var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"backcolor pb-2",staticStyle:{"min-height":"100vh"}},[_c('v-toolbar',{staticClass:"elevation-1"},[_c('v-icon',{staticClass:"text-h4",attrs:{"color":"yellow"}},[_vm._v("mdi-school")]),_c('v-toolbar-title',{staticClass:"ml-3"},[_c('span',{staticClass:"font-weight-bold",style:(_vm.$vuetify.breakpoint.smAndUp
            ? 'letter-spacing: 0.5rem'
            : 'letter-spacing: 0.2rem')},[_vm._v(" "+_vm._s(_vm.$t('admin.admin_alunoativotitle'))+" ")])])],1),_c('div',{staticClass:"ma-4 ma-md-6"},[_c('v-row',_vm._l((_vm.totalCards),function(t,i){return _c('v-col',{key:i,attrs:{"cols":"12","md":"4"}},[_c('v-card',{staticClass:"rounded-lg",staticStyle:{"height":"100%"},attrs:{"flat":""}},[_c('div',{staticClass:"d-flex align-center justify-center",staticStyle:{"height":"100%"}},[_c('div',{staticClass:"text-center",staticStyle:{"width":"30%"}},[_c('v-icon',{staticClass:"pa-3 rounded-circle",class:t.back,attrs:{"size":"40","color":t.color}},[_vm._v(" "+_vm._s(t.icon)+" ")])],1),_c('div',{staticClass:"d-flex flex-column py-6",staticStyle:{"width":"70%"}},[_c('span',{staticClass:"font-weight-medium",staticStyle:{"font-size":"1.2rem"}},[_vm._v(" "+_vm._s(t.name)+" ")]),_c('span',{staticClass:"font-weight-bold",staticStyle:{"font-size":"1.6rem"}},[_vm._v(" "+_vm._s(t.value)+" ")])])])])],1)}),1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-lg",staticStyle:{"height":"100%"},attrs:{"flat":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('admin.admin_alunosativosporescola'))+" ")]),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.tableData,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"sort-by":"Team","footer-props":{
                'items-per-page-options': [20, 45, 60, 100, -1],
                'items-per-page-all-text': _vm.$t('events.events_headers.headers1'),
                'items-per-page-text': _vm.$t('student.student_paginas'),
                'show-first-last-page': true,
              }},on:{"page-count":function($event){_vm.pageCount = $event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.engagementRate",fn:function(ref){
              var item = ref.item;
return [_c('v-chip',{staticClass:"d-flex align-center justify-center",staticStyle:{"width":"75px"},attrs:{"color":_vm.getColor(item.engagementRate),"dark":""}},[_vm._v(" "+_vm._s(item.engagementRate)+"% ")])]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }