<template>
  <div class="backcolor pb-2" style="min-height: 100vh">
    <v-toolbar class="elevation-1">
      <v-icon color="light-green " class="text-h4">
        mdi-human-male-board
      </v-icon>
      <v-toolbar-title class="ml-3">
        <span
          class="font-weight-bold"
          :style="
            $vuetify.breakpoint.smAndUp
              ? 'letter-spacing: 0.5rem'
              : 'letter-spacing: 0.2rem'
          "
        >
          {{ $t('school.school_qtd.aulaaplicadatitle') }}
        </span>
      </v-toolbar-title>
    </v-toolbar>
    <div class="ma-4 ma-md-6">
      <v-row>
        <v-col cols="12">
          <v-card class="rounded-lg" flat style="height: 100%">
            <v-card-title class="mb-6 font-weight-bold">
              {{ $t('school.school_qtd.aulasaplica') }}
              <v-spacer></v-spacer>
              <v-text-field
                color="blue"
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('label.label_pesquisa')"
                single-line
                hide-details
                style="max-width: 50%"
              ></v-text-field>
            </v-card-title>
            <v-card-text style="height: 70vh; overflow-y: scroll">
              <v-data-table
                :headers="headers"
                :items="activeClass"
                :search="search"
                sort-by="TeamName"
                fixed-header
                :items-per-page="-1"
                hide-default-footer
              >
                <template v-slot:item.AppliedClasses="{ item }">
                  <v-chip
                    style="width: 50px"
                    :color="getColor(item.AppliedClasses, item.NumberClasses)"
                    dark
                    class="d-flex align-center justify-center"
                  >
                    {{ item.AppliedClasses }}
                  </v-chip>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-btn
                    small
                    outlined
                    rounded
                    color="darkblue"
                    @click="seeDetails(item)"
                    class="font-weight-medium"
                  >
                    {{ $t('botoes.botao_veraula') }}
                    <v-icon right> mdi-arrow-right </v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <v-dialog
      v-model="seeTeamClasses"
      max-width="70%"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card class="rounded-lg">
        <v-card-title class="darkblue white--text">
          <span>{{ $t('teams.team_headers.header1') }}</span> <v-spacer></v-spacer>
          <v-btn icon text color="white" @click="seeTeamClasses = false">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="my-4">
          <v-alert
            :color="colorLookLight(index)"
            text
            dense
            outlined
            v-for="(data, index) in classes"
            :key="data.id"
            :icon="$vuetify.breakpoint.xsOnly ? false : 'mdi-bookshelf'"
            :prominent="!$vuetify.breakpoint.xsOnly"
            class="my-3"
          >
            <v-row align="center" no-gutters>
              <v-col
                cols="12"
                sm="8"
                lg="9"
                class="grow font-weight-bold black--text"
              >
                <div style="font-size: 1.1rem">
                  {{ data.title }}
                </div>
                <div style="font-size: 0.9rem">
                  {{ data.project }}
                </div>
              </v-col>

              <v-col cols="12" sm="4" lg="3" class="pa-0 ma-0 black--text">
                <div>
                  <v-icon v-if="!data.applied" left color="red">
                    mdi-close
                  </v-icon>
                  <v-icon v-if="data.applied" left color="green">
                    mdi-check
                  </v-icon>
                  <span
                    v-text="data.applied ? $t('label.label_aplicado.ativo') : $t('label.label_aplicado.desativo')"
                  />
                </div>
              </v-col>
            </v-row>
          </v-alert>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
const axios = require("axios");
const url = process.env.VUE_APP_API_URL;
const token = "Baerer " + localStorage.getItem("accessToken");
const authorization = { headers: { authorization: token } };

export default {
  components: {},
  data() {
    return {
      search: "",
      headers: [
        { text: this.$t('school.school_headers.heardes5'), value: "TeamName", width: "35%" },
        { text: this.$t('admin.admin_numeroaula'), value: "NumberClasses", width: "15%" },
        { text: this.$t('school.school_qtd.aulasaplica'), value: "AppliedClasses", width: "15%" },
        { text: "", value: "actions", sortable: false, align: "end" },
      ],
      activeClass: [],
      seeTeamClasses: false,
      classes: [],
      classColors: [
        { light: "pink ", dark: "darkpink" },
        { light: "cyan", dark: "darkcyan" },
        { light: "yellow", dark: "darkyellow" },
      ],
    };
  },
  created() {
    this.getActiveClass();
  },
  methods: {
    async getActiveClass() {
      try {
        let res = await axios.get(
          `${url}/access/appliedClass/forManager`,
          authorization
        );
        this.activeClass = res.data.result;
      } catch (error) {
        console.error(error);
      }
    },
    getColor(applied, amount) {
      if (applied === 0) return "red lighten-1";
      else if (applied < amount) return "orange lighten-1";
      else return "green lighten-1";
    },
    seeDetails(item) {
      const body = {
        teacherId: item.teacherId,
        teamId: item.teamId,
        schoolId: item.schoolId,
        courseId: item.courseId,
      };
      this.getClassDetails(body);

      this.seeTeamClasses = true;
    },
    getClassDetails(body) {
      try {
        axios
          .post(`${url}/appliedClasses/applied`, body, {
            headers: {
              authorization: token,
            },
          })
          .then((res) => {
            this.classes = res.data;
          })
          .catch((error) => {
            console.error(error);
          });
      } catch (error) {
        console.error(error);
      }
    },
    colorLookLight(index) {
      let position = (index + 1) % 3;
      let color = this.classColors[position].light;
      return color;
    },
  },
};
</script>

<style></style>
