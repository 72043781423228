<template>
  <div class="backcolor pb-2" style="min-height: 100vh">
    <v-toolbar class="elevation-1">
      <v-icon color="yellow" class="text-h4">mdi-school</v-icon>
      <v-toolbar-title class="ml-3">
        <span
          class="font-weight-bold"
          :style="
            $vuetify.breakpoint.smAndUp
              ? 'letter-spacing: 0.5rem'
              : 'letter-spacing: 0.2rem'
          "
        >
          {{ $t('admin.admin_alunoativotitle') }}
        </span>
      </v-toolbar-title>
    </v-toolbar>
    <div class="ma-4 ma-md-6">
      <v-row>
        <v-col cols="12" md="4" v-for="(t, i) in totalCards" :key="i">
          <v-card class="rounded-lg" flat style="height: 100%">
            <div
              class="d-flex align-center justify-center"
              style="height: 100%"
            >
              <div style="width: 30%" class="text-center">
                <v-icon
                  size="40"
                  class="pa-3 rounded-circle"
                  :class="t.back"
                  :color="t.color"
                >
                  {{ t.icon }}
                </v-icon>
              </div>
              <div style="width: 70%" class="d-flex flex-column py-6">
                <span style="font-size: 1.2rem" class="font-weight-medium">
                  {{ t.name }}
                </span>
                <span style="font-size: 1.6rem" class="font-weight-bold">
                  {{ t.value }}
                </span>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card class="rounded-lg" flat style="height: 100%">
            <v-card-title> {{ $t('admin.admin_alunosativosporescola') }} </v-card-title>
            <v-card-text>
              <v-data-table
                :headers="headers"
                :items="tableData"
                @page-count="pageCount = $event"
                :page.sync="page"
                :items-per-page="itemsPerPage"
                sort-by="Team"
                :footer-props="{
                  'items-per-page-options': [20, 45, 60, 100, -1],
                  'items-per-page-all-text': $t('events.events_headers.headers1'),
                  'items-per-page-text': $t('student.student_paginas'),
                  'show-first-last-page': true,
                }"
              >
                <template v-slot:item.engagementRate="{ item }">
                  <v-chip
                    style="width: 75px"
                    :color="getColor(item.engagementRate)"
                    dark
                    class="d-flex align-center justify-center"
                  >
                    {{ item.engagementRate }}%
                  </v-chip>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
const axios = require("axios");
const url = process.env.VUE_APP_API_URL;
const token = "Baerer " + localStorage.getItem("accessToken");
const authorization = { headers: { authorization: token } };

export default {
  components: {},
  data() {
    return {
      totalCards: [
        {
          icon: "mdi-account-group",
          name: this.$t('gestor.gestor_total.total1'),
          value: 0,
          color: "light-blue accent-4",
          back: "blue-bg",
        },
        {
          icon: "mdi-human-male-board",
          name: this.$t('gestor.gestor_total.total2'),
          value: 0,
          color: "pink accent-2",
          back: "pink-bg",
        },
        {
          icon: "mdi-school",
          name: this.$t('gestor.gestor_total.total3'),
          value: 0,
          color: "teal accent-3",
          back: "cyan-bg",
        },
      ],
      tableData: [],
      headers: [
        { text: this.$t('regisResponsavel.painel.escola'), value: "school" },
        { text: this.$t('gestor.gestor_total.total4'), value: "total_students" },
        { text: this.$t('gestor.gestor_total.total2'), value: "total_students_accepted_terms" },
        { text: this.$t('gestor.gestor_total.total3'), value: "engagementRate" },
      ],
    };
  },
  created() {
    this.getActiveStudents();
  },
  methods: {
    async getActiveStudents() {
      try {
        let res = await axios.get(
          `${url}/access/studentActive/forManager`,
          authorization
        );
        this.tableData = res.data.result;

        for (let i = 0; i < this.tableData.length; i++) {
          const { total_students_accepted_terms, total_students } =
            this.tableData[i];
          const engagementRate =
            (total_students_accepted_terms / total_students) * 100;
          this.tableData[i].engagementRate = engagementRate.toFixed(1);
        }

        console.log(this.tableData);

        this.totalCards[0].value = this.sumTotalStudents(this.tableData);
        this.totalCards[1].value = this.sumTotalActiveStudents(this.tableData);
        this.totalCards[2].value = this.getEngagementRate(
          this.totalCards[1].value,
          this.totalCards[0].value
        );
      } catch (error) {
        console.error(error);
      }
    },
    sumTotalStudents(schools) {
      return schools.reduce((sum, school) => sum + school.total_students, 0);
    },
    sumTotalActiveStudents(schools) {
      return schools.reduce(
        (sum, school) => sum + school.total_students_accepted_terms,
        0
      );
    },
    getEngagementRate(value, total) {
      let res = ((value / total) * 100).toFixed(1);
      return res + "%";
    },
    getColor(engagementRate) {
      if (engagementRate === 0) return "#F44336";
      else if (engagementRate < 10) return "deep-orange darken-1";
      else if (engagementRate < 20) return "deep-orange lighten-1";
      else if (engagementRate < 30) return "orange lighten-1";
      else if (engagementRate < 40) return "amber lighten-1";
      else if (engagementRate < 50) return "yellow lighten-1";
      else if (engagementRate < 60) return "lime lighten-1";
      else if (engagementRate < 70) return "lime darken-1";
      else if (engagementRate < 80) return "light-green lighten-1";
      else if (engagementRate < 90) return "light-green darken-1";
      else return "green lighten-1";
    },
  },
};
</script>

<style>
.pink-bg {
  background-color: #ff00ff2a;
}
.cyan-bg {
  background-color: #00ccff2a;
}
.blue-bg {
  background-color: #0000ff1c;
}
</style>
