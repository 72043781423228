<template>
  <div class="backcolor pb-2" style="min-height: 100vh">
    <v-toolbar class="elevation-1">
      <v-icon color="#00BCD4" class="text-h4">mdi-account-group</v-icon>
      <v-toolbar-title class="ml-3">
        <span
          class="font-weight-bold"
          :style="
            $vuetify.breakpoint.smAndUp
              ? 'letter-spacing: 0.5rem'
              : 'letter-spacing: 0.2rem'
          "
        >
          {{ $t('school.school_titleturma') }}
        </span>
      </v-toolbar-title>
    </v-toolbar>
    <div class="ma-4 ma-md-6">
      <v-row>
        <v-col cols="12" lg="6">
          <v-card class="rounded-lg" flat style="height: 100%">
            <v-card-title
              style="font-size: 1.25rem"
              class="font-weight-bold d-flex justify-space-between"
            >
              <span>{{ $t('gestor.gestor_turmaescola') }}</span>

              <span
                style="
                  font-size: 0.75rem;
                  text-decoration: underline;
                  cursor: pointer;
                "
                class="mt-2 font-weight-medium"
                @click="seeDetails = true"
              >
                {{ $t('admin.admin_verdetalhe') }}
                <v-icon x-small color="black">mdi-arrow-right</v-icon>
              </span>
            </v-card-title>
            <v-card-text>
              <TeamChart :chartTeam="chartData" ref="TeamChart" />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" lg="6">
          <v-card class="rounded-lg" flat style="height: 100%">
            <v-card-title
              class="d-flex justify-space-between align-center flex-row mb-4"
            >
              <span
                style="font-size: 1.25rem; width: 50%"
                class="font-weight-bold"
              >
                {{ $t('gestor.gestor_alunosturmo') }}
              </span>
              <v-text-field
                color="blue"
                v-model="search"
                append-icon="mdi-magnify"
                label="Pesquisar"
                single-line
                hide-details
                style="width: 50%"
              ></v-text-field>
            </v-card-title>
            <v-card-text style="height: 70vh; overflow-y: scroll">
              <v-data-table
                :headers="headers"
                :items="studentTable"
                :search="search"
                @page-count="pageCount = $event"
                :page.sync="page"
                :items-per-page="itemsPerPage"
                sort-by="Team"
                :footer-props="{
                  'items-per-page-options': [20, 45, 60, 100, -1],
                  'items-per-page-all-text': $t('events.events_headers.headers1'),
                  'items-per-page-text': $t('student.student_paginas'),
                  'show-first-last-page': true,
                }"
              >
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <v-dialog
      v-model="seeDetails"
      max-width="70%"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card class="rounded-lg">
        <v-card-title class="darkblue white--text">
          Todas as Turmas
          <v-spacer></v-spacer>
          <v-btn icon text color="white" @click="seeDetails = false">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text style="height: 80vh; overflow-y: scroll">
          <v-data-table
            :headers="fullHeaders"
            :items="studentTable"
            :search="fullSearch"
            @page-count="pageCount = $event"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            sort-by="Team"
            :footer-props="{
              'items-per-page-options': [20, 45, 60, 100, -1],
              'items-per-page-all-text': $t('events.events_headers.headers1'),
              'items-per-page-text': $t('student.student_paginas'),
              'show-first-last-page': true,
            }"
          >
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
const axios = require("axios");
const url = process.env.VUE_APP_API_URL;
const token = "Baerer " + localStorage.getItem("accessToken");
const authorization = { headers: { authorization: token } };
import TeamChart from "./Charts/ChartTeam.vue";

export default {
  components: {
    TeamChart,
  },
  data() {
    return {
      seeDetails: false,
      page: 1,
      pageCount: null,
      itemsPerPage: 25,
      search: "",
      fullSearch: "",
      chartData: { name: [], data: [] },
      headers: [
        { text: this.$t('student.student_headers.headers3'), value: "Team" },
        { text: this.$t('regisResponsavel.painel.escola'), value: "School" },
        { text: this.$t('school.school_qtd.qtddealuno'), value: "StudentsAmount" },
      ],
      fullHeaders: [
        { text: this.$t('school.school_headers.heardes5'), value: "Team" },
        { text: this.$t('certificado.cert_headers.headers1'), value: "Teacher" },
        { text: this.$t('school.school_curso'), value: "Course" },
        { text: this.$t('regisResponsavel.painel.escola'), value: "School" },
      ],
      studentTable: [
        {
          id: null,
          Team: "",
          Teacher: "",
          Course: "",
          School: "",
          StudentsAmount: 0,
        },
      ],
    };
  },
  created() {
    this.getTeamsAmount();
    this.getStudentAmount();
  },
  methods: {
    async getTeamsAmount() {
      try {
        let res = await axios.get(
          `${url}/access/numberTeams/forManager`,
          authorization
        );

        this.chartData = { name: [], data: [] };

        for (let index = 0; index < res.data.result.length; index++) {
          const { SchoolNick, NumTeams } = res.data.result[index];
          this.chartData.name.push(SchoolNick);
          this.chartData.data.push(NumTeams);
        }

        this.$refs.TeamChart.updateData();
      } catch (error) {
        console.error(error);
      }
    },
    async getStudentAmount() {
      try {
        let res = await axios.get(
          `${url}/access/numberStudentOnTeams/forManager`,
          authorization
        );

        this.studentTable = res.data.result;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style></style>
