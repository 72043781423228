<template>
  <div>
    <apexchart
      type="donut"
      :height="$vuetify.breakpoint.smAndDown ? 200 : 450"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  props: {
    chartTeam: {},
  },
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      series: [],
      chartOptions: {
        legend: {
          show: true,
          position: "right",
          fontWeight: 600,
          onItemClick: {
            toggleDataSeries: false,
          },
        },
        labels: [],
        colors: [
          "#F44336",
          "#673AB7",
          "#03A9F4",
          "#4CAF50",
          "#FFEB3B",
          "#FF5722",
          "#E91E63",
          "#3F51B5",
          "#00BCD4",
          "#8BC34A",
          "#FFC107",
          "#9C27B0",
          "#2196F3",
          "#009688",
          "#CDDC39",
          "#FF9800",
        ],
        chart: {
          type: "donut",
        },
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          pie: {
            donut: {
              background: "transparent",
              size: "65%",
              labels: {
                show: true,
                name: {
                  color: "#000000",
                  offsetY: 25,
                },
                value: {
                  color: "#000000",
                  fontSize: "32px",
                  fontWeight: 600,
                  offsetY: -15,
                },
                total: {
                  show: true,
                  label: "Total",
                  fontWeight: 400,
                  color: "#000000",
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b;
                    }, 0);
                  },
                },
              },
            },
          },
        },
        tooltip: {
          enabled: false,
        },
      },
    };
  },
  created() {
    this.updateClass();
  },
  methods: {
    updateClass() {
      setTimeout(() => {
        this.series = [];
        const data = this.chartTeam.data;
        for (let i = 0; i < data.length; i++) {
          const element = data[i];
          this.series.push(element);
        }

        this.chartOptions = {
          labels: this.chartTeam.name,
        };
      }, 500);
    },
  },
};
</script>

<style></style>
