var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"backcolor pb-2",staticStyle:{"min-height":"100vh"}},[_c('v-toolbar',{staticClass:"elevation-1"},[_c('v-icon',{staticClass:"text-h4",attrs:{"color":"#00BCD4"}},[_vm._v("mdi-account-group")]),_c('v-toolbar-title',{staticClass:"ml-3"},[_c('span',{staticClass:"font-weight-bold",style:(_vm.$vuetify.breakpoint.smAndUp
            ? 'letter-spacing: 0.5rem'
            : 'letter-spacing: 0.2rem')},[_vm._v(" "+_vm._s(_vm.$t('school.school_titleturma'))+" ")])])],1),_c('div',{staticClass:"ma-4 ma-md-6"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-card',{staticClass:"rounded-lg",staticStyle:{"height":"100%"},attrs:{"flat":""}},[_c('v-card-title',{staticClass:"font-weight-bold d-flex justify-space-between",staticStyle:{"font-size":"1.25rem"}},[_c('span',[_vm._v(_vm._s(_vm.$t('gestor.gestor_turmaescola')))]),_c('span',{staticClass:"mt-2 font-weight-medium",staticStyle:{"font-size":"0.75rem","text-decoration":"underline","cursor":"pointer"},on:{"click":function($event){_vm.seeDetails = true}}},[_vm._v(" "+_vm._s(_vm.$t('admin.admin_verdetalhe'))+" "),_c('v-icon',{attrs:{"x-small":"","color":"black"}},[_vm._v("mdi-arrow-right")])],1)]),_c('v-card-text',[_c('TeamChart',{ref:"TeamChart",attrs:{"chartTeam":_vm.chartData}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-card',{staticClass:"rounded-lg",staticStyle:{"height":"100%"},attrs:{"flat":""}},[_c('v-card-title',{staticClass:"d-flex justify-space-between align-center flex-row mb-4"},[_c('span',{staticClass:"font-weight-bold",staticStyle:{"font-size":"1.25rem","width":"50%"}},[_vm._v(" "+_vm._s(_vm.$t('gestor.gestor_alunosturmo'))+" ")]),_c('v-text-field',{staticStyle:{"width":"50%"},attrs:{"color":"blue","append-icon":"mdi-magnify","label":"Pesquisar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-card-text',{staticStyle:{"height":"70vh","overflow-y":"scroll"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.studentTable,"search":_vm.search,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"sort-by":"Team","footer-props":{
                'items-per-page-options': [20, 45, 60, 100, -1],
                'items-per-page-all-text': _vm.$t('events.events_headers.headers1'),
                'items-per-page-text': _vm.$t('student.student_paginas'),
                'show-first-last-page': true,
              }},on:{"page-count":function($event){_vm.pageCount = $event},"update:page":function($event){_vm.page=$event}}})],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"70%","fullscreen":_vm.$vuetify.breakpoint.mobile},model:{value:(_vm.seeDetails),callback:function ($$v) {_vm.seeDetails=$$v},expression:"seeDetails"}},[_c('v-card',{staticClass:"rounded-lg"},[_c('v-card-title',{staticClass:"darkblue white--text"},[_vm._v(" Todas as Turmas "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","text":"","color":"white"},on:{"click":function($event){_vm.seeDetails = false}}},[_c('v-icon',[_vm._v(" mdi-close ")])],1)],1),_c('v-card-text',{staticStyle:{"height":"80vh","overflow-y":"scroll"}},[_c('v-data-table',{attrs:{"headers":_vm.fullHeaders,"items":_vm.studentTable,"search":_vm.fullSearch,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"sort-by":"Team","footer-props":{
            'items-per-page-options': [20, 45, 60, 100, -1],
            'items-per-page-all-text': _vm.$t('events.events_headers.headers1'),
            'items-per-page-text': _vm.$t('student.student_paginas'),
            'show-first-last-page': true,
          }},on:{"page-count":function($event){_vm.pageCount = $event},"update:page":function($event){_vm.page=$event}}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }